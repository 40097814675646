import React, { Component, useCallback, useEffect } from "react";
import {
	Switch,
	Route as NativeRoute,
	Redirect,
	withRouter,
	useHistory,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Login from "./Login";
import Home from "./pages/home/Home";
import Header from "./structure/Header";
import Sidebar from "./structure/Sidebar";
import Footer from "./structure/Footer";
import ManageProjects from "./pages/home/ManageProjects";
import ManageTeam from "./pages/home/ManageTeam";
import FileManager from "./pages/home/FileManager";
import CanvasWrapper from "./pages/message/left/CanvasWrapper";
import Kanban from "./pages/message/left/Kanban";
import Paper from "@material-ui/core/Paper";
import Mousetrap from "mousetrap";
import Cookies from "js-cookie";
import QRCode from "./structure/QRCode";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { observer } from "mobx-react";
import snackbarStore from "./lib/SnackbarStore";
import RightPane from "./pages/message/right/RightPane";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";
import API from "./API";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CircularProgress } from "@material-ui/core";
import Prototype from "./pages/message/right/Prototype";
import tooSmallIcon from "./img/tooSmallIcon.svg";
import Bowser from "bowser";
import tuzagTCS from "./img/tuzagTCS.svg";
import { autorun, configure, observable } from "mobx";
import ManageProfile from "./pages/home/ManageProfile";
import ManageUsers from "./pages/home/ManageUsers";
import Signup from "./Signup";
import firebase from "firebase/app";
import "firebase/analytics";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import Search from "./pages/home/Search";
import ExportImportContent from "./pages/home/ExportImportContent";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { SnackbarProvider } from "notistack";
import CanvasViewer from "./pages/CanvasViewer";
import ContentTesting from "./pages/home/ContentTesting/ContentTesting";
import { logError } from "./lib/ErrorLogger";
import TaskManager from "./pages/home/TaskManager/TaskManager";
import TranscriptTesting from "./pages/home/TranscriptTesting";
import Backdrop from "@material-ui/core/Backdrop";
import AttributeBuilderTree from "./pages/home/AttributeBuilder/AttributeBuilderTree";
import Glossary from "./pages/home/GlossaryTree/GlossaryTree";
import SearchEmpty from "./pages/home/SearchEmpty";
import ContentLength from "./pages/home/ContentLength";
import TestCase from "./pages/home/TestCase";
import Retrievers from "./pages/home/Retrievers";
import GhostKiller from "./pages/home/GhostKiller";
import PrototypeStart from "./pages/message/right/PrototypeStart";
import BulkMessageUpdate from "./pages/home/BulkMessageUpdate";

const firebaseConfig = {
	apiKey: "AIzaSyDLR3bKiWm8h5kGX_fbT_7Lj0gEXE9TBro",
	authDomain: "tuzagtcsv3-prod.firebaseapp.com",
	databaseURL: "https://tuzagtcsv3-prod.firebaseio.com",
	projectId: "tuzagtcsv3-prod",
	storageBucket: "tuzagtcsv3-prod.appspot.com",
	messagingSenderId: "301299572564",
	appId: "1:301299572564:web:ed854c6c50cdb7b151f2c1",
	measurementId: "G-MF1PVZXKSQ",
};

// import { Mouse } from "@material-ui/icons";

const theme = createMuiTheme({
	palette: {
		primary: { 500: "#d1373f" },
		secondary: { main: "#2F3D47" },
	},
});

configure({
	enforceActions: "never",
	computedRequiresAction: false,
});

export const appStore = new observable({
	canvasID: null,
	isAdmin: false,
	loaded: null,
	selectedProject: null,
	selectedProjectName: null,
	selectedProjectAccessMap: {
		create: false,
		read: false,
		update: false,
		delete: false,
	},
	smallFooter: !!localStorage.smallFooter,
});
export const usersStore = new observable({ users: [] });

const Route = ({ children, ...rest }) => {
	if (Cookies.get("token")) {
		return <NativeRoute {...rest}>{children}</NativeRoute>;
	} else {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { referrer: window.location.pathname },
				}}
			/>
		);
	}
};

const browserInfo = Bowser.parse(window.navigator.userAgent);

export default () => {
	const history = useHistory();

	useEffect(() => {
		return history.listen((location) => {
			firebase.analytics().setCurrentScreen(location.pathname);
			firebase
				.analytics()
				.logEvent("page_view", { page_path: location.pathname });
		});
	}, [history]);

	return <App />;
};

const App = observer(
	class App extends Component {
		state = { screenWidth: window.innerWidth };
		_ismounted = false;

		constructor() {
			super();

			firebase.initializeApp(firebaseConfig);

			if (process.env.REACT_APP_ENV !== "local") {
				firebase.analytics();
				if (Cookies.get("userEmail")) {
					firebase.analytics().setUserProperties({
						email: Cookies.get("userEmail"),
						tcsVersion: process.env.REACT_APP_VERSION,
					});
				}
			}
		}

		componentWillUnmount() {
			this._ismounted = false;
			if (this.disposer) {
				this.disposer();
			}
		}

		async componentDidMount() {
			this._ismounted = true;
			// dom.watch();

			this.disposer = autorun(async () => {
				if (appStore.selectedProject && this._ismounted) {
					const { users } = await API(`/commentsUsers`, "POST", {
						env: window.location.hostname.replace(".tuzagtcs.com", ""),
						projectID: appStore.selectedProject,
					});
					usersStore.users = users;
				}
			});

			Mousetrap.prototype.stopCallback = (e, element, combo) => {
				if (
					element.getAttribute("data-slate-editor") === "true" &&
					(combo === "alt+down" ||
						combo === "alt+left" ||
						combo === "alt+right")
				) {
					return true;
				}
				return false;
			};

			window.onresize = () => {
				this.setState({ screenWidth: window.innerWidth });
			};

			window.onorientationchange = () => {
				this.setState({ screenWidth: window.innerWidth });
			};
		}

		componentDidCatch(error, errorInfo) {
			logError(error, errorInfo);
		}

		screenSizeSolution() {
			if (browserInfo.platform.type === "mobile") {
				return (
					<h3>
						We currently don't support mobile. Please try a tablet, laptop, or
						desktop computer.
					</h3>
				);
			} else if (browserInfo.platform.type === "tablet") {
				return (
					<h3>
						Please try rotating your {browserInfo.platform.model || "device"} or
						use a larger tablet, laptop, or desktop computer.
					</h3>
				);
			} else {
				return <h3>Try making the window bigger.</h3>;
			}
		}

		render() {
			return (
				<SnackbarProvider>
					<div
						style={{
							display: this.state.screenWidth < 850 ? "flex" : "none",
							justifyContent: "center",
							alignItems: "center",
							height: "100vh",
							marginLeft: 15,
							marginRight: 15,
						}}
					>
						<Paper
							style={{
								textAlign: "center",
								padding: 15,
								flex: 1,
							}}
						>
							<img
								src={tuzagTCS}
								style={{
									width: "100%",
									maxWidth: 300,
									marginBottom: 30,
									marginTop: 30,
								}}
								alt="tuzag TCS logo"
							/>
							<h3>
								Oof. This{" "}
								{browserInfo.platform.type === "desktop" ? "window" : "screen"}{" "}
								size is a little too small.
							</h3>
							<img
								src={tooSmallIcon}
								style={{ maxWidth: 150, paddingTop: 30, paddingBottom: 30 }}
								alt="squeezed icon"
							/>
							{this.screenSizeSolution()}
						</Paper>
					</div>
					<div
						style={{
							display: this.state.screenWidth >= 850 ? "block" : "none",
							height: 0,
						}}
					>
						<ThemeProvider theme={theme}>
							<Switch>
								<NativeRoute path="/login" component={Login} />
								<NativeRoute path="/signup" component={Signup} />
								<NativeRoute path="/home" component={observer(HomeRoutes)} />
								<NativeRoute path="/filemanager" component={MessageRoutes} />
								<NativeRoute path="/kanban" component={MessageRoutes} />
								<NativeRoute path="/canvas" component={MessageRoutes} />
								<NativeRoute path="/qr" component={QRCode} />
								<NativeRoute
									path="/canvasViewer"
									exact
									component={CanvasViewer}
								/>
								<NativeRoute
									path="/canvasViewer/:canvasID"
									component={CanvasViewer}
									exact
								/>
								<NativeRoute path="/" component={observer(HomeRoutes)} />
							</Switch>
							<Snackbar
								open={snackbarStore.open}
								autoHideDuration={5000}
								onClose={() => {
									snackbarStore.open = false;
								}}
								anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
							>
								<Alert
									elevation={6}
									severity={snackbarStore.severity.toLowerCase()}
									style={{ marginBottom: 100 }}
									onClose={() => {
										snackbarStore.open = false;
									}}
								>
									<AlertTitle>{snackbarStore.severity}</AlertTitle>
									{snackbarStore.message}
								</Alert>
							</Snackbar>
						</ThemeProvider>
					</div>
				</SnackbarProvider>
			);
		}
	}
);

class HomeRoutes extends Component {
	render() {
		return (
			<>
				<Header />
				<Sidebar />
				<div
					style={{
						marginLeft: 270,
						padding: 20,
						marginTop: 54,
						height: `calc(100vh - ${appStore.smallFooter ? "125" : "195"}px)`,
						overflow: "auto",
					}}
				>
					<Switch>
						<Route path="/home/manageProjects" component={ManageProjects} />
						<Route path="/home/manageTeam" component={ManageTeam} />
						<Route path="/home/manageUsers" component={ManageUsers} />
						<Route
							path="/home/attributeBuilder"
							component={AttributeBuilderTree}
						/>
						<Route path="/home/glossary" component={Glossary} />
						<Route path="/home/retrievers" component={Retrievers} />
						<Route path="/home/manageProfile" component={ManageProfile} />
						<Route
							path="/home/exportImportContent"
							component={ExportImportContent}
						/>
						<Route path="/home/contentLength" component={ContentLength} />
						<Route path="/home/taskManager" component={TaskManager} />
						<Route path="/home/contentTesting" component={ContentTesting} />
						<Route path="/home/transcript" component={TranscriptTesting} />
						<Route path="/home/searchEmpty" component={SearchEmpty} />
						<Route path="/home/search" component={Search} />
						<Route path="/home/testCase" component={TestCase} />
						<Route path="/home/ghostKiller" component={GhostKiller} />
						<Route
							path="/home/bulkMessageUpdate"
							component={BulkMessageUpdate}
						/>
						<Route path="/home/prototypeStart" component={PrototypeStart} />
						<Route
							path="/"
							render={(props) => {
								return <Home {...props} />;
							}}
						/>
					</Switch>
				</div>
				<Footer />
				<QuickSwitcher />
			</>
		);
	}
}

const MessageRoutes = observer(
	class MessageRoutes extends Component {
		state = {
			open: false,
			paneTitle: null,
		};

		setPaneTitle = (paneTitle) => {
			this.setState({ paneTitle });
		};

		render() {
			let mainStyling = {
				height: `calc(100vh - ${appStore.smallFooter ? "85" : "155"}px)`,
				overflow: "auto",
			};
			return (
				<>
					<Header />
					<div
						style={{
							marginTop: 53,
							// marginBottom: 102
						}}
						className="clearfix"
					>
						<div style={mainStyling}>
							<Switch>
								<Route
									path="/canvas/:canvasID?"
									render={(props) => {
										return <CanvasWrapper {...props} appStore={appStore} />;
									}}
								/>

								<Route
									path="/filemanager"
									render={(props) => (
										<FileManager {...props} appStore={appStore} />
									)}
								/>

								<Route
									path="/kanban"
									render={(props) => <Kanban {...props} dashboard={false} />}
								/>
							</Switch>
						</div>

						<Switch>
							<Route
								path="/*/:canvasID/:messageID/prototype"
								render={(props) => {
									return (
										<DialogPane
											paneTitle={this.state.paneTitle}
											setPaneTitle={this.setPaneTitle}
										>
											<Prototype {...props} appStore={appStore} />
										</DialogPane>
									);
								}}
							/>

							<Route
								path="/canvas/:canvasID/:messageID/element/:elementID"
								render={(props) => {
									return (
										<DialogPane
											paneTitle={this.state.paneTitle}
											setPaneTitle={this.setPaneTitle}
										>
											<RightPane
												{...props}
												view={"canvas"}
												appStore={appStore}
												setPaneTitle={this.setPaneTitle}
											/>
										</DialogPane>
									);
								}}
							/>
							<Route
								path="/canvas/:canvasID/:messageID"
								render={(props) => {
									return (
										<DialogPane
											paneTitle={this.state.paneTitle}
											setPaneTitle={this.setPaneTitle}
										>
											<RightPane
												{...props}
												view={"canvas"}
												appStore={appStore}
												setPaneTitle={this.setPaneTitle}
											/>
										</DialogPane>
									);
								}}
							/>
							<Route
								path="/kanban/:canvasID/:messageID"
								render={(props) => {
									return (
										<DialogPane
											paneTitle={this.state.paneTitle}
											setPaneTitle={this.setPaneTitle}
										>
											<RightPane
												{...props}
												view={"kanban"}
												appStore={appStore}
												setPaneTitle={this.setPaneTitle}
											/>
										</DialogPane>
									);
								}}
							/>
							<Route
								path="/filemanager/:canvasID/:messageID"
								render={(props) => {
									return (
										<DialogPane
											paneTitle={this.state.paneTitle}
											setPaneTitle={this.setPaneTitle}
										>
											<RightPane
												{...props}
												view={"filemanager"}
												appStore={appStore}
												setPaneTitle={this.setPaneTitle}
											/>
										</DialogPane>
									);
								}}
							/>
						</Switch>
					</div>
					<Footer />
					<QuickSwitcher />
				</>
			);
		}
	}
);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const loadingScreen = new observable({ loading: false });

const DialogPane = withRouter(
	observer(({ children, paneTitle, setPaneTitle, ...props }) => {
		const close = useCallback(() => {
			const href = window.location.href;

			if (href.includes("prototype")){
				props.history.push(`/home/prototypeStart`)
			} else {
				if (window.graph) {
					const allCells = Object.values(window.graph.getModel().cells);
					const currentMessage = allCells.find((cell) => {
						return cell.id === props.match.params.messageID;
					});

					if (currentMessage) {
						window.graph.scrollCellToVisible(currentMessage, true);
					}
				}
				if (href.includes("prototype")) {
					props.history.push(props.match.url.replace(/\/s.*/, ""));
				} else if (href.includes("element") && href.includes("canvas")) {
					const nextUrl = props.match.url
						.replace(`/element/${props.match.params.elementID}`, "")
						.replace(/\/\d+$/, "");
					props.history.push(nextUrl);
				} else if (href.includes("canvas")) {
					props.history.push(props.match.url.replace(/\/\d+$/, ""));
				} else {
					props.history.push(props.match.url.replace(/\/\d+\/\d+$/, ""));
				}
				setPaneTitle(null);
			}
		}, [props, setPaneTitle]);

		useEffect(() => {
			Mousetrap.bind("`", () => {
				close();
			});

			return () => {
				Mousetrap.unbind("`");
			};
		}, [close]);

		return (
			<>
				<Dialog
					fullScreen
					TransitionComponent={Transition}
					open={true}
					PaperProps={{
						style: {
							overflow: "body",
							backgroundColor: "#f5f5f5",
						},
					}}
				>
					<AppBar style={{ position: "relative" }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="close"
								onClick={close}
							>
								<CloseIcon />
							</IconButton>
							{paneTitle}
						</Toolbar>
					</AppBar>
					{children}
				</Dialog>
				<Backdrop
					style={{ zIndex: 10000, color: "#fff" }}
					open={loadingScreen.loading}
					transitionDuration={666}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</>
		);
	})
);

const QuickSwitcher = withRouter(
	class extends Component {
		state = {
			open: false,
			searchTerm: "",
			results: [],
			selectedIndex: null,
			redirect: null,
			mode: null,
		};

		componentDidMount = () => {
			Mousetrap.bind("mod+shift+k", (e) => {
				if (appStore.selectedProject) {
					this.setState({ open: !this.state.open, mode: "canvas" });
				}
			});
			Mousetrap.bind("mod+k", (e) => {
				if (appStore.selectedProject) {
					this.setState({ open: !this.state.open, mode: "message" });
				}
			});

			Mousetrap.bind("down", (e) => {
				if (this.state.open) {
					e.preventDefault();

					if (this.state.selectedIndex < this.state.results.length - 1) {
						this.setState({ selectedIndex: this.state.selectedIndex + 1 });
					}
				}
			});

			Mousetrap.bind("up", (e) => {
				if (this.state.open) {
					e.preventDefault();

					if (this.state.selectedIndex > 0) {
						this.setState({ selectedIndex: this.state.selectedIndex - 1 });
					}
				}
			});

			Mousetrap.bind("enter", (e) => {
				if (this.state.open && this.state.selectedIndex !== null) {
					e.preventDefault();
					const result = this.state.results[this.state.selectedIndex];
					this.setState({
						open: false,
						searchTerm: "",
						selectedIndex: null,
						results: [],
					});
					if (this.state.mode === "message") {
						this.props.history.push(
							`/canvas/${result.canvasID}/${result.shapeID}`
						);
					} else if (this.state.mode === "canvas") {
						this.props.history.push(`/canvas/${result.id}`);
					}
				}
			});
		};

		search = debounce(async () => {
			const { results } = await API(`/${this.state.mode}Search`, "POST", {
				searchTerm: this.state.searchTerm,
				projectID: appStore.selectedProject,
			});

			this.setState({ results, selectedIndex: 0, loading: false });
		}, 1000);

		render() {
			return (
				<Dialog
					open={this.state.open}
					onClose={() => {
						this.setState({
							open: false,
							searchTerm: "",
							results: [],
							selectedIndex: null,
						});
					}}
				>
					<DialogContent style={{ minWidth: 400 }}>
						<TextField
							autoFocus
							margin="dense"
							label={`${this.state.mode} name`}
							fullWidth
							onChange={(evt) => {
								this.setState({ searchTerm: evt.target.value, loading: true });
								this.search();
							}}
							value={this.state.searchTerm}
						/>
						{this.state.loading ? (
							<div style={{ justifyContent: "center", display: "flex" }}>
								<CircularProgress size={28} />
							</div>
						) : (
							<List>
								{this.state.results.map((result, index) => {
									return (
										<ListItem
											key={result.id}
											selected={index === this.state.selectedIndex}
											onClick={() => {
												this.setState({
													open: false,
													searchTerm: "",
													selectedIndex: null,
													results: [],
												});
												if (this.state.mode === "message") {
													this.props.history.push(
														`/canvas/${result.canvasID}/${result.shapeID}`
													);
												} else if (this.state.mode === "canvas") {
													this.props.history.push(`/canvas/${result.id}`);
												}
											}}
											style={{ cursor: "pointer" }}
										>
											{result.name}
										</ListItem>
									);
								})}
							</List>
						)}
					</DialogContent>
				</Dialog>
			);
		}
	}
);
